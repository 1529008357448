
import DepartmentsMainPage from "./MainPageComp/departmentsMainPage.js"
import Home from "./MainPageComp/Home.js"

import NEWS from "./NavbarElements/NEWS.js"

const MainHomePage = () =>{
  return(
<div>
    <Home />
    <NEWS />
    <DepartmentsMainPage/>
   
   
</div>
    
      
  )}
export default MainHomePage